import { Link } from 'gatsby';
import React, { useEffect, useState, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './Header.scss';
import Navigation from '../Navigation/Navigation';
import UniversalLink from '../UniversalLink';
import langContext from '../../hooks/langContext';
import LangSwitcher from '../LangSwitcher/LangSwitcher';

const Header = ({ page }) => {
  const [hasBg, setHasBg] = useState(false);
  const [isCookie, setIsCookie] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { lang, setLang } = useContext(langContext);

  function setBg() {
    if (window.pageYOffset > 50) {
      setHasBg(true);
      return;
    }
    setHasBg(false);
  }

  useEffect(() => {
    setIsCookie(!!document.getElementsByClassName('cookie-teaser')[0]);

    setBg();
    window.onscroll = () => {
      setBg();
    };

    if (menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, []);

  const { wp } = useStaticQuery(
    graphql`
      {
        wp {
          themeOptions {
            ACFThemePageLink {
              orderLink {
                url
                title
              }
            }
          }
        }
      }
    `
  );

  const themeOptions = wp.themeOptions.ACFThemePageLink;

  return (
    <header className={`header ${(hasBg && !menuOpen) || isCookie ? 'has--bg' : ''}`}>
      <Navigation menuOpen={menuOpen} />
      <div className="header__inner">
        <Link to="https://livy-home.com" className="header__logo" />
        <UniversalLink className="button is--primary" to={themeOptions.orderLink.url}>
          {themeOptions.orderLink.title}
        </UniversalLink>
        {page.translated.length ? <LangSwitcher page={page} /> : ''}

        <button
          className={`header__toggle hamburger hamburger--collapse ${menuOpen ? 'is-active' : ''}`}
          type="button"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
