import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { useStaticQuery, graphql } from 'gatsby';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import contentParser from 'gatsby-wpgraphql-inline-images';
import config from '../../../config';
import Cookies from 'universal-cookie';
import './CookieBanner.scss';
import UniversalLink from '../UniversalLink';
import videoFinishedContext from '../../hooks/useContext';
const cookies = new Cookies();
const cookieName = 'allowStatistics';
const cookieNameFunctional = 'allowFunctional';
const isBrowser = typeof window !== 'undefined';

const CookieBanner = () => {
  const { wp } = useStaticQuery(graphql`
    query CookieBannerQuery {
      wp {
        themeOptions {
          ACFThemePageLink {
            cookieBannerAcceptLabel
            cookieBannerContent
            cookiesPageLink {
              title
              url
            }
            cookiesBannerFooterLinks {
              link {
                title
                url
              }
            }
            cookieBannerTitle
          }
        }
      }
    }
  `);

  const { videoFinished } = useContext(videoFinishedContext);

  const cookieBannerFields = wp.themeOptions.ACFThemePageLink;
  const location = useLocation();
  if (isBrowser) {
    initializeAndTrack(location);
  }
  const cookieValue = cookies.get(cookieName);

  const [show, setShow] = useState(false);
  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    if (url.search('cookies') == -1) {
      setShow(!cookieValue ? true : false);
    }
  });

  const setCookieBanner = (value) => {
    setShow(false);

    if (value) {
      cookies.set(cookieName, 'true', config.cookieSettings);
      cookies.set(cookieNameFunctional, 'true', config.cookieSettings);
    }
  };

  return (
    <div className={`cookie-banner ${show && videoFinished ? 'is--visible' : ''}`}>
      <div className="container">
        <div className="cookie-banner__row">
          <div className="cookie-banner__inner">
            <div className="cookie-banner__title">{cookieBannerFields.cookieBannerTitle}</div>

            <div className="cookie-banner__text">
              {contentParser({ content: cookieBannerFields.cookieBannerContent }, config.inlineImagesOptions)}
            </div>
          </div>

          <div className="cookie-banner__buttons">
            <button className="cookie-banner__button button is--primary" onClick={() => setCookieBanner(true)}>
              {cookieBannerFields.cookieBannerAcceptLabel}
            </button>

            {cookieBannerFields.cookiesPageLink && (
              <UniversalLink
                onClick={() => setShow(false)}
                to={cookieBannerFields.cookiesPageLink.url}
                className="cookie-banner__button button is--secondary"
              >
                {cookieBannerFields.cookiesPageLink.title}
              </UniversalLink>
            )}
          </div>
          <div className="cookie-banner__legal-menu-wrapper">
            {cookieBannerFields.cookiesBannerFooterLinks
              ? cookieBannerFields.cookiesBannerFooterLinks.map((legal, i) => (
                  <UniversalLink key={i} to={legal.link.url} className="cookie-banner__link">
                    {legal.link.title}
                  </UniversalLink>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
