import getLocalFromCode from './locales';

const createSlug = (page) => {
  const pageLocale = getLocalFromCode(page.translated[0].locale.locale);

  let slug = '/';
  if (pageLocale !== 'de') {
    slug += 'en/';
  }

  if (!page.translated[0].isFrontPage && !page.isFrontPage) {
    slug += page.translated[0].slug;
  }
  return slug;
};

export default createSlug;
