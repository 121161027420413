import React, { useEffect, useState, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';

import './Navigation.scss';
import UniversalLink from '../UniversalLink';
import NavigationItem from './NavigationItem';
import SVGParser from '../SVGParser';
import langContext from '../../hooks/langContext';

export default withBreakpoints(({ menuOpen }) => {
  const [rendered, setRendered] = useState(false);
  const [activeSubNavigation, setActiveSubNavigation] = useState(false);
  const [isSubNavigationActive, setIsSubNavigationActive] = useState(false);
  const { lang, setLang } = useContext(langContext);

  const { menuQuery, supportMenuQuery } = useStaticQuery(
    graphql`
      {
        menuQuery: allWpMenu(filter: { locations: { eq: PRIMARY } }) {
          edges {
            node {
              language
              menuItems {
                nodes {
                  id
                  path
                  target
                  url
                  label
                  description
                  cssClasses
                  parentId
                  childItems {
                    nodes {
                      id
                      path
                      target
                      title
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        }
        supportMenuQuery: allWpMenu(filter: { locations: { eq: SUPPORT } }) {
          edges {
            node {
              language
              menuItems {
                nodes {
                  id
                  path
                  target
                  url
                  label
                  description
                  cssClasses
                  parentId
                  childItems {
                    nodes {
                      id
                      path
                      target
                      title
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    setRendered(true);
  });

  const menus =
    menuQuery && menuQuery.edges.length ? menuQuery.edges.filter((menuLang) => menuLang.node.language === lang) : '';

  const menu =
    menus.length && menus[0].node.menuItems
      ? menus[0].node.menuItems.nodes.filter((menuItem) => menuItem.parentId === null)
      : null;

  const supportMenues =
    supportMenuQuery && supportMenuQuery.edges.length
      ? supportMenuQuery.edges.filter((menuLang) => menuLang.node.language === lang)
      : '';

  const support =
    supportMenues.length && supportMenues[0].node.menuItems
      ? supportMenues[0].node.menuItems.nodes.filter((menuItem) => menuItem.parentId === null)
      : null;

  const openSub = (key) => {
    setIsSubNavigationActive(true);
    setActiveSubNavigation(key);
  };

  const closeSubNavigation = () => {
    setIsSubNavigationActive(false);
  };

  return (
    <div className={`navigation ${menuOpen ? 'is--open' : ''}`} style={{ display: !rendered ? 'none' : 'block' }}>
      <div className="container">
        <div className="navigation__inner">
          {menu ? (
            <>
              <ul className="navigation__list" id="navigation">
                {menu.map((menuItem, i) => (
                  <NavigationItem
                    menuItem={menuItem}
                    key={i}
                    id={i}
                    openSub={openSub}
                    active={activeSubNavigation === i && isSubNavigationActive}
                  />
                ))}
              </ul>
              {menu.map((menuItem, i) =>
                menuItem.childItems.nodes.length ? (
                  <ul
                    className={`navigation__sub ${
                      activeSubNavigation === i && isSubNavigationActive && menuOpen ? 'is--active' : ''
                    }`}
                    key={i}
                    id="navigation"
                  >
                    {menuItem.childItems.nodes.map((subItem, subKey) => (
                      <div className="navigation__item" key={subKey}>
                        <UniversalLink to={subItem.path} className="navigation__link">
                          {subItem.label}
                        </UniversalLink>
                      </div>
                    ))}
                    <button className="navigation__back" onClick={() => closeSubNavigation()}>
                      <SVGParser name="arrow-back" />
                    </button>
                  </ul>
                ) : null
              )}
            </>
          ) : null}
          {menu ? (
            <div className="navigation__support" id="navigation">
              {support.map((menuItem, i) => (
                <UniversalLink to={menuItem.path} className="navigation__link is--small" key={i}>
                  {menuItem.label}
                </UniversalLink>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});
