import { Link as GatsbyLink } from 'gatsby';
import React, { useContext } from 'react';
import './LangSwitcher.scss';
import langContext from '../../hooks/langContext';
import getLocalFromCode from '../../utils/locales';
import createSlug from '../../utils/getSlug';

const LangSwitcher = (props) => {
  const { lang } = useContext(langContext);
  const translated = props.page.translated;

  return (
    <div className="lang-switcher">
      <div className="lang-switcher__lang is--active">{lang}</div>
      <span>|</span>
      {
        <GatsbyLink className="lang-switcher__lang" to={createSlug(props.page)}>
          {getLocalFromCode(translated[0].locale.locale)}
        </GatsbyLink>
      }
    </div>
  );
};

export default LangSwitcher;
