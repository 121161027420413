import { Link } from 'gatsby';
import React, { useContext } from 'react';
import SVGParser from '../SVGParser';
import { useStaticQuery, graphql } from 'gatsby';
import './footer.scss';
import UniversalLink from '../UniversalLink';
import langContext from '../../hooks/langContext';

const Footer = () => {
  const { lang, setLang } = useContext(langContext);

  const { legalMenuQuery, wp } = useStaticQuery(
    graphql`
      {
        legalMenuQuery: allWpMenu(filter: { locations: { eq: LEGAL } }) {
          edges {
            node {
              language
              menuItems {
                nodes {
                  url
                  label
                  parentId
                  path
                  childItems {
                    nodes {
                      url
                      label
                      path
                    }
                  }
                }
              }
            }
          }
        }
        wp {
          themeOptions {
            ACFThemePageLink {
              copyrightText
              socialMediaLinks {
                icon
                link
              }
            }
          }
        }
      }
    `
  );
  const menus =
    legalMenuQuery && legalMenuQuery.edges.length
      ? legalMenuQuery.edges.filter((menuLang) => menuLang.node.language === lang)
      : '';

  const legalMenu =
    menus.length && menus[0].node.menuItems
      ? menus[0].node.menuItems.nodes.filter((menuItem) => menuItem.parentId === null)
      : null;

  const themeOptions = wp.themeOptions.ACFThemePageLink;
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="container">
        <div className="footer__inner">
          <Link to="/" className="footer__logo" />
          <div className="footer__inner-links-wrapper">
            {themeOptions.socialMediaLinks &&
              themeOptions.socialMediaLinks.map((link, i) => (
                <UniversalLink key={i} to={link.link} className="footer__link-icon">
                  <SVGParser name={link.icon} />
                </UniversalLink>
              ))}
          </div>
        </div>
        <div className="footer__links-wrapper">
          <div className="footer__legal-menu-wrapper">
            {legalMenu
              ? legalMenu.map((legal, i) => (
                  <UniversalLink key={i} to={legal.path} className="footer__link">
                    {legal.label}
                  </UniversalLink>
                ))
              : null}
          </div>
          <div className="footer__text">
            © {year} {themeOptions.copyrightText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
