import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import '../sass/main.scss';
import CookieBanner from './CookieBanner/CookieBanner';
import { Helmet } from 'react-helmet';
import langContext from '../hooks/langContext';
import createSlug from '../utils/getSlug';
import getLocalFromCode from '../utils/locales';
import { useLocation } from '@reach/router';

const Layout = (props) => {
  const { lang, setLang } = useContext(langContext);

  const { wp } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);

  const location = useLocation();

  return (
    <SEOContext.Provider value={{ global: wp.seo }}>
      <Helmet>
        <link rel="alternate" href={location.pathname} hrefLang={lang} />
        {props.page.translated && props.page.translated.length && (
          <link
            rel="alternate"
            href={createSlug(props.page)}
            hrefLang={getLocalFromCode(props.page.translated[0].locale.locale)}
          />
        )}
      </Helmet>
      <div className="body-wrapper">
        <Header page={props.page} />
        <main id="content" role="main" className="content-wrapper">
          {props.children}
        </main>
        <Footer />
        <CookieBanner />
      </div>
    </SEOContext.Provider>
  );
};

export default Layout;
