import React from 'react';
import DataProtectionIcon from '../images/icons/data-protection-icon.svg';
import PlayStoreIcon from '../images/icons/play-store-icon.svg';
import AppStoreIcon from '../images/icons/app-store-icon.svg';
import KameraIcon from '../images/icons/kamera-icon.svg';
import NachtsichtIcon from '../images/icons/nachtsicht-icon.svg';
import NachtlichtIcon from '../images/icons/nachtlicht-icon.svg';
import IndirekteLedsIcon from '../images/icons/indirekte-leds-icon.svg';
import VideosequenzenIcon from '../images/icons/videosequenzen-icon.svg';
import BadAirQualityIcon from '../images/icons/bad-air-quality-icon.svg';
import CoSensorIcon from '../images/icons/co-sensor-icon.svg';
import AudioStreamingIcon from '../images/icons/audiostreaming-icon.svg';
import LarmwarnungIcon from '../images/icons/larmwarnung-icon.svg';
import RauchalarmerkennungIcon from '../images/icons/rauchalarmerkennung-icon.svg';
import WegeAudioIcon from '../images/icons/wege-audio-icon.svg';
import KristallklareSoundqualitatIcon from '../images/icons/kristallklare-soundqualitat-icon.svg';
import GerauschIcon from '../images/icons/gerausch-icon.svg';
import BewegungsIcon from '../images/icons/bewegungs-icon.svg';
import SchimmelIcon from '../images/icons/schimmel-icon.svg';
import ArrowRight from '../images/icons/arrow-right.svg';
import ArrowBack from '../images/icons/arrow-back.svg';
import VideoOverlayClose from '../images/icons/video-overlay-close.svg';
import Facebook from '../images/icons/facebook-icon.svg';
import Instagram from '../images/icons/instagram-icon.svg';
import Youtube from '../images/icons/youtube-icon.svg';
import AlarmIcon from '../images/icons/alarm-icon.svg';
import AirIcon from '../images/icons/air-icon.svg';
import BubblesIcon from '../images/icons/bubbles-icon.svg';
import CoIcon from '../images/icons/co-icon.svg';
import CrownIcon from '../images/icons/crown-icon.svg';
import DiamondIcon from '../images/icons/diamond-icon.svg';
import HazeIcon from '../images/icons/haze-icon.svg';
import NoiseIcon from '../images/icons/noise-icon.svg';
import NotificationIcon from '../images/icons/notification-icon.svg';
import RadarIcon from '../images/icons/radar-icon.svg';
import RhythymIcon from '../images/icons/rhythym-icon.svg';
import SensorIcon from '../images/icons/sensor-icon.svg';
import SmokeIcon from '../images/icons/smoke-icon.svg';
import SpeechSmileIcon from '../images/icons/speech-smile-icon.svg';
import VoicePrintIcon from '../images/icons/voiceprint-icon.svg';
import VolumeDownIcon from '../images/icons/volume-down-icon.svg';
import ArrowRightOutline from '../images/icons/arrow-outline-right.svg';

const components = {
  'data-protection-icon': DataProtectionIcon,
  'play-store-icon': PlayStoreIcon,
  'app-store-icon': AppStoreIcon,
  'kamera-icon': KameraIcon,
  'nachtsicht-icon': NachtsichtIcon,
  'nachtlicht-icon': NachtlichtIcon,
  'indirekte-leds-icon': IndirekteLedsIcon,
  'videosequenzen-icon': VideosequenzenIcon,
  'co-sensor-icon': CoSensorIcon,
  'bad-air-quality-icon': BadAirQualityIcon,
  'audiostreaming-icon': AudioStreamingIcon,
  'larmwarnung-icon': LarmwarnungIcon,
  'rauchalarmerkennung-icon': RauchalarmerkennungIcon,
  'wege-audio-icon': WegeAudioIcon,
  'kristallklare-soundqualitat-icon': KristallklareSoundqualitatIcon,
  'gerausch-icon': GerauschIcon,
  'bewegungs-icon': BewegungsIcon,
  'schimmel-icon': SchimmelIcon,
  'arrow-right': ArrowRight,
  'arrow-back': ArrowBack,
  'video-overlay-close': VideoOverlayClose,
  'facebook-icon': Facebook,
  'instagram-icon': Instagram,
  'youtube-icon': Youtube,
  'alarm-icon': AlarmIcon,
  'air-icon': AirIcon,
  'bubbles-icon': BubblesIcon,
  'co-icon': CoIcon,
  'crown-icon': CrownIcon,
  'diamond-icon': DiamondIcon,
  'haze-icon': HazeIcon,
  'noise-icon': NoiseIcon,
  'notification-icon': NotificationIcon,
  'radar-icon': RadarIcon,
  'rhythym-icon': RhythymIcon,
  'sensor-icon': SensorIcon,
  'smoke-icon': SmokeIcon,
  'speech-smile-icon': SpeechSmileIcon,
  'voiceprint-icon': VoicePrintIcon,
  'volume-down-icon': VolumeDownIcon,
  'arrow-right-outline': ArrowRightOutline,
};

const SVGParser = ({ name, classes, key, ...other }) => {
  const Component = components[name];
  if (Component) {
    return <Component id={key} className={classes} {...other} />;
  } else return null;
};

export default SVGParser;
